import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StoreService } from '@app/services';

@Component({
  selector: 'app-generate-qrcode',
  templateUrl: './generate-qrcode.component.html',
  styleUrls: ['./generate-qrcode.component.css']
})
export class GenerateQrcodeComponent implements OnInit {

  qrCode: string = null;
  productName: string = null;
  reference: string = null;
  location: string = null;
  storeName: String = null;

  constructor(
    public dialogRef: MatDialogRef<GenerateQrcodeComponent>,
    private storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.qrCode = data.product.elementid;
    this.productName = data.product.productname;
    this.reference = data.product.reference;
    this.location = data.product.location;
   
    // Get store name
    this.storeService.getById(data.product.storename).subscribe(store => {
      this.storeName = store.storename;
    });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

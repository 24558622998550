import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Element } from '@app/models/element';

@Injectable({ providedIn: 'root' })
export class ElementService {

    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) { }

    // Get All
    getAll() {
        return this.http.get<Element[]>(`${environment.apiUrl}/elements`);
    }

    // Get by ID
    getById(id) {
        return this.http.get<Element>(`${environment.apiUrl}/elements/${id}`);
    }

    // Get by store id
    getByStore(id) {
      return this.http.get<Element[]>(`${environment.apiUrl}/elements/bystore/${id}`);
    }

    // Get by user id
    getByUser(id) {
      return this.http.get<Element[]>(`${environment.apiUrl}/elements/byuser/${id}`);
    }

    // Create
    create(data: Element) {
        return this.http.post<Element>(`${environment.apiUrl}/elements/register`, data);
    }

    // Update
    update(id, data: Element) {
        return this.http.put<Element>(`${environment.apiUrl}/elements/${id}`, data);
    }

    // Delete
    delete(id) {
        return this.http.delete<Element>(`${environment.apiUrl}/elements/${id}`);
    }

}
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-read-qrcode',
  templateUrl: './read-qrcode.component.html',
  styleUrls: ['./read-qrcode.component.css']
})
export class ReadQrcodeComponent implements OnInit {

  scanResult: string = null;

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerModule;

  constructor(
    public dialogRef: MatDialogRef<ReadQrcodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.scanResult = "close";
    this.dialogRef.close();
  }

  /* Emits the result as string, after a valid QR code was scanned */
  scanSuccessHandler(result) {
    this.scanResult = result ;
    this.dialogRef.close();
  }

  /* Emitted when some error occours during the scan proccess */
  scanErrorHandler(error){
    console.log(error);
    this.scanResult = "error";
    this.dialogRef.close();
  }
}

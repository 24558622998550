import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";

import { ElementService, ProductService, StoreService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';

import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '@app/models';

@Component({
  selector: 'app-add-store-product',
  templateUrl: './add-store-product.component.html',
  styleUrls: ['./add-store-product.component.css']
})

export class AddStoreProductComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cols: Number;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetElementForm', { static: true }) myNgForm;

  elementForm: FormGroup;
  productList: any = [];
  productListInStore: String[] = [];
  currentStore: any = [];
  storeId: string;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private elementService: ElementService,
    private productService: ProductService,
    private storeService: StoreService
  ) {
  }
  
  private calculateCols(width: number): void {
    this.cols = width < 1024 ? 1 : 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth);
    this.actRoute.queryParams.subscribe(params => {
      this.storeId = params.storeId;
      this.storeService.getById(this.storeId).subscribe(store => {
        this.currentStore = store;
        this.elementService.getByStore(this.storeId).subscribe((elements) => {
          elements.map((element) => {
            this.productService.getById(element.productname).subscribe((product) => {
              this.productListInStore.push(product.name.toString());
            })
          });
          this.productService.getAll().subscribe(products => {
            this.productList = products.filter((product) => !this.productListInStore.includes(product.name.toString()));
          });
        })
      })
    });
    this.submitBookForm();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.productList.slice())
      );
  }


  /* Reactive book form */
  submitBookForm() {
    this.elementForm = this.fb.group({
      elementname: [''],
      storename: [this.storeId],
      productname: ['', [Validators.required]],
      location: ['', [Validators.required]],
      currentQuantity: ['', [Validators.required]],
      maxQuantity: ['', [Validators.required]],
      minQuantity: ['', [Validators.required]],
      replenishmentStatus: ['']
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.elementForm.controls[controlName].hasError(errorName);
  }

  /* Submit */
  submitElementForm() {
    if (this.elementForm.valid) {
      // this.elementForm.patchValue({ "productname": this.elementForm.get("productname") });

      //Create a unique key
      var productName = this.elementForm.get("productname").value;
      var eltName = this.storeId + productName.id;
      this.elementForm.patchValue({ elementname: eltName });

      //Update replenishment status      
      if (this.elementForm.get("currentQuantity").value >= this.elementForm.get("minQuantity").value) {
        this.elementForm.patchValue({ replenishmentStatus: UserMessage.inStockStatus });
      } else {
        this.elementForm.patchValue({ replenishmentStatus: UserMessage.waitingStatus });
      }

      this.elementService.create(this.elementForm.value).subscribe((res) => {
        this.ngZone.run(() => this.router.navigate(['/elements'], { queryParams: { storeId: this.currentStore.id }, skipLocationChange: true }));
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigate(['/elements'], { queryParams: { storeId: this.currentStore.id } }));
  }

  /* Allow to display the product name*/
  displayFn(product?: Product): String | undefined {
    return product ? product.reference : undefined;
  }

  /* Autocomplete filter */
  private _filter(reference: string): Product[] {
    const filterValue = reference.toLowerCase();

    return this.productList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}

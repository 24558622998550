import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Product } from '@app/models/product';

@Injectable({ providedIn: 'root' })
export class ProductService {

    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) { }

    // Get All
    getAll() {
        return this.http.get<Product[]>(`${environment.apiUrl}/products`);
    }

    // Get by ID
    getById(id) {
        return this.http.get<Product>(`${environment.apiUrl}/products/${id}`);
    }

    // Create
    create(data) {
        return this.http.post<Product>(`${environment.apiUrl}/products/register`, data);
    }

    // Update
    update(id, data) {
        return this.http.put<Product>(`${environment.apiUrl}/products/${id}`, data);
    }

    // Delete
    delete(id) {
        return this.http.delete<Product>(`${environment.apiUrl}/products/${id}`);
    }

}

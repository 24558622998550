import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Angular 8 components */

import { LoginComponent } from '@app/components/login/login.component';
import { HomeComponent } from '@app/components/home/home.component';

import { ListMystoreComponent } from '@app/components/mystore/list-mystore/list-mystore.component';
import { EditMystoreComponent } from '@app/components/mystore/edit-mystore/edit-mystore.component';

import { AddClientComponent } from '@app/components/client/add-client/add-client.component';
import { EditClientComponent } from '@app/components/client/edit-client/edit-client.component';
import { ListClientComponent } from '@app/components/client/list-client/list-client.component';

import { AddProductComponent } from '@app/components/product/add-product/add-product.component';
import { EditProductComponent } from '@app/components/product/edit-product/edit-product.component';
import { ListProductComponent } from '@app/components/product/list-product/list-product.component';

import { AddStoreComponent } from '@app/components/store/add-store/add-store.component';
import { EditStoreComponent } from '@app/components/store/edit-store/edit-store.component';
import { ListStoreComponent } from '@app/components/store/list-store/list-store.component';

import { AddStoreProductComponent } from '@app/components/store-products/add-store-product/add-store-product.component';
import { EditStoreProductComponent } from '@app/components/store-products/edit-store-product/edit-store-product.component';
import { ListStoreProductComponent } from '@app/components/store-products/list-store-product/list-store-product.component';

import { ReadQrcodeComponent } from '@app/components/_shared/read-qrcode/read-qrcode.component';
import { GenerateQrcodeComponent } from '@app/components/_shared/generate-qrcode/generate-qrcode.component';
import { AuthGuard } from '@app/components/_helpers/auth.guard';
import { ProductHistoryComponent } from './components/product-history/product-history.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },  
  { path: 'my-store/edit-my-store', component: EditMystoreComponent },
  { path: 'my-store', component: ListMystoreComponent },
  { path: 'clients/add-client', component: AddClientComponent },
  { path: 'clients/edit-client', component: EditClientComponent },
  { path: 'clients', component: ListClientComponent },  
  { path: 'products/add-product', component: AddProductComponent },
  { path: 'products/edit-product', component: EditProductComponent },
  { path: 'products', component: ListProductComponent },
  { path: 'stores/add-store', component: AddStoreComponent },
  { path: 'stores/edit-store', component: EditStoreComponent },
  { path: 'stores', component: ListStoreComponent },
  { path: 'elements/add-element', component: AddStoreProductComponent },
  { path: 'elements/edit-element', component: EditStoreProductComponent },
  { path: 'elements', component: ListStoreProductComponent },
  { path: 'read-qrcode', component: ReadQrcodeComponent },
  { path: 'generate-qrcode', component: GenerateQrcodeComponent },
  { path: 'product-history', component: ProductHistoryComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
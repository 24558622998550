import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { InfoDialogComponent } from '@app/components/_shared/info-dialog/info-dialog.component';

@Injectable({ providedIn: 'root' })
export class InfoService {

    constructor(public dialog: MatDialog) { }

    openDialog(data): void {
        this.dialog.open(InfoDialogComponent, {
            width: '320px',
            data: data
        });
    }

    openFadeDialog(data, timeout): void {
        let dialog = this.dialog.open(InfoDialogComponent, {
            width: '320px',
            data: data
        });
        setTimeout(function () {
            dialog.close();
        }, timeout)
    }
}
/* USER MESSAGE */

export class UserMessage {

    static readonly confirmUpdateClient = "Êtes-vous sûr de vouloir modifier ce client ?";
    static readonly confirmDeleteClient = "Êtes-vous sûr de vouloir supprimer ce client ?";

    static readonly confirmUpdateProduct = "Êtes-vous sûr de vouloir modifier ce produit ?";
    static readonly confirmDeleteProduct = "Êtes-vous sûr de vouloir supprimer ce produit ?";  

    static readonly confirmUpdateStore = "Êtes-vous sûr de vouloir modifier ce magasin ?";
    static readonly confirmDeleteStore = "Êtes-vous sûr de vouloir supprimer ce magasin ?";

    static readonly confirmUpdateProfil = "Confirmer ces modifications ?";

    static readonly storeUpdateOK = "Le stock a été mis à jour";
    static readonly qrCodeError = "Une erreur est survenue durant le scan du QR Code"
    static readonly unavailableProduct = "Ce produit n'est pas disponible dans votre magasin"

    static readonly waitingStatus = "En Attente";    
    static readonly inStockStatus = "En Stock";

    static readonly elementUpdateError = "Le produit est déjà sélectionné"

    constructor() {
    }
}

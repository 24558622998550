import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from '@app/components/_shared/error-dialog/error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorService {

    constructor(public dialog: MatDialog) { }
    
    openDialog(data): void {
        this.dialog.open(ErrorDialogComponent, {
            width: '320px',
            data: data
        });
    }

    openFadeDialog(data, timeout): void {
        let dialog = this.dialog.open(ErrorDialogComponent, {
            width: '320px',
            data: data
        });
        setTimeout(function () {
            dialog.close();
        }, timeout)
    }
}
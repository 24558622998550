import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { UserService, ErrorService } from 'src/app/services';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})

export class AddClientComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cols: Number;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetClientForm', { static: true }) myNgForm;

  userForm: FormGroup;
  selected = 'user';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private userService: UserService,
    private errorService: ErrorService
  ) {
  }

  private calculateCols(width: number): void {
    this.cols = width < 1024 ? 1 : 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth);
    this.submitBookForm();
  }

  /* Reactive book form */
  submitBookForm() {
    this.userForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      role: ['', [Validators.required]]
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }

  /* Submit */
  submitUserForm() {
    if (this.userForm.valid) {
      this.userService.create(this.userForm.value).subscribe(res => {
        this.ngZone.run(() => this.router.navigateByUrl('/clients'))
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/clients'));
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { FlexLayoutModule } from '@angular/flex-layout';

/* Angular 8 components */

import { LoginComponent } from '@app/components/login/login.component';
import { HomeComponent } from '@app/components/home/home.component';

import { ListMystoreComponent } from '@app/components/mystore/list-mystore/list-mystore.component';
import { EditMystoreComponent } from '@app/components/mystore/edit-mystore/edit-mystore.component';

import { AddClientComponent } from '@app/components/client/add-client/add-client.component';
import { EditClientComponent } from '@app/components/client/edit-client/edit-client.component';
import { ListClientComponent } from '@app/components/client/list-client/list-client.component';

import { AddProductComponent } from '@app/components/product/add-product/add-product.component';
import { EditProductComponent } from '@app/components/product/edit-product/edit-product.component';
import { ListProductComponent } from '@app/components/product/list-product/list-product.component';

import { AddStoreComponent } from '@app/components/store/add-store/add-store.component';
import { EditStoreComponent } from '@app/components/store/edit-store/edit-store.component';
import { ListStoreComponent } from '@app/components/store/list-store/list-store.component';

import { AddStoreProductComponent } from '@app/components/store-products/add-store-product/add-store-product.component';
import { EditStoreProductComponent } from '@app/components/store-products/edit-store-product/edit-store-product.component';
import { ListStoreProductComponent } from '@app/components/store-products/list-store-product/list-store-product.component';

import { ReadQrcodeComponent } from '@app/components/_shared/read-qrcode/read-qrcode.component';
import { GenerateQrcodeComponent } from '@app/components/_shared/generate-qrcode/generate-qrcode.component';

import { ConfirmDialogComponent } from '@app/components/_shared/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '@app/components/_shared/info-dialog/info-dialog.component';
import { ErrorDialogComponent } from '@app/components/_shared/error-dialog/error-dialog.component';

import { ProfileComponent } from './components/_shared/profile/profile.component';

/* Helpers */
import { JwtInterceptor } from '@app/components/_helpers/jwt.interceptor';
import { ErrorInterceptor } from '@app/components/_helpers/error.interceptor';

/* QR Code reader */
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angularx-qrcode';

/* Print */
import { NgxPrintModule } from 'ngx-print';

/* Charts */
import { ChartsModule } from 'ng2-charts';

/* Image Cropper */
import { ImageCropperModule } from 'ngx-image-cropper';

/* Angular material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from '@app/material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

/* Angular 8 HTTP services */

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* Reactive form services in Angular 8 */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportModalComponent } from './components/_shared/export-modal/export-modal.component';
import { ProductHistoryComponent } from './components/product-history/product-history.component';
import { ImageCropDialogComponent } from '@app/components/_shared/image-crop-dialog/image-crop-dialog.component';
import { ImageDropzoneComponent } from '@app/components/_shared/image-dropzone/image-dropzone.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ImageViewDialogComponent } from '@app/components/_shared/image-view-dialog/image-view-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AddClientComponent,
    EditClientComponent,
    ListClientComponent,
    AddProductComponent,
    EditProductComponent,
    ListProductComponent,
    AddStoreComponent,
    EditStoreComponent,
    ListStoreComponent,
    AddStoreProductComponent,
    EditStoreProductComponent,
    ListStoreProductComponent,
    ListMystoreComponent,
    EditMystoreComponent,
    ReadQrcodeComponent,
    GenerateQrcodeComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    ErrorDialogComponent,
    ProfileComponent,
    ExportModalComponent,
    ProductHistoryComponent,
    ImageCropDialogComponent,
    ImageDropzoneComponent,
    ImageViewDialogComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    ZXingScannerModule,
    QRCodeModule,
    NgxPrintModule,
    ChartsModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ImageCropperModule,
    MatSlideToggleModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmDialogComponent,
    InfoDialogComponent,
    ErrorDialogComponent,
    ProfileComponent,
    ExportModalComponent,
    ImageCropDialogComponent,
    ImageViewDialogComponent,
  ]
})

export class AppModule { }

import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { StoreService } from '@app/services/store.service';
import { UserService, ErrorService } from 'src/app/services';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.css']
})
export class AddStoreComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cols: Number;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetStoreForm', { static: true }) myNgForm;

  storeForm: FormGroup;
  clientList: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private storeService: StoreService,
    private userService: UserService,
    private errorService: ErrorService
  ) {
  }

  private calculateCols(width: number): void {
    this.cols = width < 1024 ? 1 : 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth);
    this.userService.getAll().subscribe(users => {
      this.clientList = users;
    })
    this.submitBookForm();
  }

  /* Reactive book form */
  submitBookForm() {
    this.storeForm = this.fb.group({
      storename: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      client: ['', [Validators.required]],
      replenishment: ['', [Validators.required]]
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.storeForm.controls[controlName].hasError(errorName);
  }

  /* Submit */
  submitStoreForm() {
    if (this.storeForm.valid) {
      this.storeService.create(this.storeForm.value).subscribe(res => {
        this.ngZone.run(() => this.router.navigateByUrl('/stores'))
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/stores'));
  }
}

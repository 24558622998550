import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthenticationService, ErrorService } from '@app/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private errorService: ErrorService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(retry(1), catchError(err => {
            switch (err.status) {
                case 0: {
                    // connection to the server interrupted
                    err.message = "Serveur temporairement inaccessible";
                    this.errorService.openDialog(err);
                    break;
                }
                case 401: {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.reload(true);
                    break;
                }
                case 413: {
                    // upload fail
                    this.errorService.openDialog(err);
                    break;
                }
                case 422: {
                    // incorrect username or password
                    break;
                }
                case 404: {
                    // incorrect username or password
                    break;
                }
              default: {
                    this.errorService.openDialog(err.error);
                    break;
                }
            }
            const error = err.error.message;
            return throwError(error);
        }))
    }
}

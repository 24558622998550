import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private translations: { [key: string]: { en: string; fr: string } } = {
    name: { en: 'name', fr: 'Désignation' },
    family: { en: 'family', fr: 'Famille' },
    reference: { en: 'reference', fr: 'Référence' },
    material: { en: 'material', fr: ' Matériau' },
    cost: { en: 'cost', fr: 'Coût' },
    supplier: { en: 'supplier', fr: ' Fournisseur' },
    consumer: { en: 'consumer', fr: ' Consommateur' },
    username: { en: 'username', fr: `Utilisateur` },
    company: { en: 'company', fr: 'Société' },
    email: { en: 'email', fr: 'Email' },
    role: { en: 'role', fr: 'Rôle' },
    storename: { en: 'storename', fr: 'Désignation' },
    client: { en: 'client', fr: 'Client associé' },
    replenishment: { en: 'replenishment', fr: 'Réappro.' },
    productname: { en: 'productname', fr: 'Produit' },
    quantityChange: { en: 'quantityChange', fr: 'QuantitéChanger' },
    createdDate: { en: 'createdDate', fr: 'Date' },
    location: { en: 'location', fr: 'Emplacement' },
    currentQuantity: { en: 'currentQuantity', fr: 'Quantité actuelle' },
    maxQuantity: { en: 'maxQuantity', fr: 'Quantité max.' },
    minQuantity: { en: 'minQuantity', fr: 'Quantité min.' },
    replenishmentStatus: { en: 'replenishmentStatus', fr: 'Réappro. statut' },
  };

  translate(key: string, lang: string): string {
    if (this.translations[key] && this.translations[key][lang]) {
        return this.translations[key][lang];
      } else {
        return key;
      }
  }
}

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { StoreService } from '@app/services/store.service';
import { UserService } from '@app/services/user.service';
import { ConfirmService, ErrorService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';

@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.css']
})
export class EditStoreComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetStoreForm', { static: true }) myNgForm;

  storeForm: FormGroup;
  clientList: any = [];
  storeId: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private actRoute: ActivatedRoute,
    private storeService: StoreService,
    private userService: UserService,
    private confirmService: ConfirmService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(params => {
      this.storeId = params.storeId;
      this.storeService.getById(this.storeId).subscribe(store => {
        this.storeForm = this.fb.group({
          storename: [store.storename, [Validators.required]],
          reference: [store.reference, [Validators.required]],
          client: [store.client, [Validators.required]],
          replenishment: [store.replenishment, [Validators.required]]
        })
        this.userService.getAll().subscribe(users => {
          this.clientList = users;
        })
      })
    })
    this.updateBookForm();
  }

  /* Reactive book form */
  updateBookForm() {
    this.storeForm = this.fb.group({
      storename: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      client: ['', [Validators.required]],
      replenishment: ['', [Validators.required]]
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.storeForm.controls[controlName].hasError(errorName);
  }

  /* Update */
  updateStoreForm() {
    if (this.storeForm.valid) {
      let confirmDialog = this.confirmService.openDialog(UserMessage.confirmUpdateStore);
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.storeService.update(this.storeId, this.storeForm.value).subscribe(res => {
            this.ngZone.run(() => this.router.navigateByUrl('/stores'))
          });
        }
        else {
          this.ngZone.run(() => this.router.navigateByUrl('/stores'))
        }
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/stores'));
  }
}

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators,  } from '@angular/forms';

import { ProductService } from '@app/services/product.service';
import { ConfirmService, ErrorService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetProductForm', { static: true }) myNgForm;

  productForm: FormGroup;
  productId: string;
  productImage: File;
  productImagePath: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private actRoute: ActivatedRoute,
    private productService: ProductService,
    private confirmService: ConfirmService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.productService.getById(this.productId).subscribe(product => {
        this.productImagePath = product.image;
        this.productForm = this.fb.group({
          name: [product.name, [Validators.required]],
          family: [product.family, [Validators.required]],
          reference: [product.reference, [Validators.required]],
          material: [product.material, [Validators.required]],
          cost: [product.cost, [Validators.required]],
          supplier: [product.supplier, [Validators.required]],
          consumer: [product.consumer, [Validators.required]],
        });
      });
    });
    this.updateBookForm();
  }

  /* Reactive book form */
  updateBookForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]],
      family: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      material: ['', [Validators.required]],
      cost: ['', [Validators.required]],
      supplier: ['', [Validators.required]],
      consumer: ['', [Validators.required]],
    });
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.productForm.controls[controlName].hasError(errorName);
  }

  /* Update */
  updateProductForm() {
    if (this.productForm.valid) {
      const confirmDialog = this.confirmService.openDialog(UserMessage.confirmUpdateProduct);
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          const formData = new FormData();
          formData.append('name', this.productForm.value.name);
          formData.append('family', this.productForm.value.family);
          formData.append('reference', this.productForm.value.reference);
          formData.append('material', this.productForm.value.material);
          formData.append('cost', this.productForm.value.cost);
          formData.append('supplier', this.productForm.value.supplier);
          formData.append('consumer', this.productForm.value.consumer);

          if (this.productImage) {
            formData.append('image', this.productImage);
          }

          this.productService.update(this.productId, formData).subscribe((res) => {
            this.ngZone.run(() => this.router.navigateByUrl('/products'));
          });
        } else {
          this.ngZone.run(() => this.router.navigateByUrl('/products'));
        }
      });
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/products'));
  }

  changeImage(image: File) {
    this.productImage = image;
  }
}

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";

import { ElementService, ProductService, StoreService, ConfirmService, ErrorService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';
import { Product } from '@app/models';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-store-product',
  templateUrl: './edit-store-product.component.html',
  styleUrls: ['./edit-store-product.component.css']
})
export class EditStoreProductComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetElementForm', { static: true }) myNgForm;

  elementForm: FormGroup;
  productList: any = [];
  currentStore: any = [];
  elementId: string;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;
  productListInStore: String[] = [];
  currentProductId: any;
  currentProduct: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private actRoute: ActivatedRoute,
    private elementService: ElementService,
    private productService: ProductService,
    private storeService: StoreService,
    private confirmService: ConfirmService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(params => {
      this.elementId = params.elementId;
      this.elementService.getById(this.elementId).subscribe(element => {
        this.currentProductId = element.productname;
        this.productService.getById(this.currentProductId).subscribe(product => {
          this.currentProduct = product.id;
          this.storeService.getById(element.storename).subscribe(store => {
            this.currentStore = store;
            this.elementForm = this.fb.group({
              elementname: [element.elementname],
              storename: [this.currentStore.id],
              productname: [this.currentProduct, [Validators.required]],
              location: [element.location, [Validators.required]],
              currentQuantity: [element.currentQuantity, [Validators.required]],
              maxQuantity: [element.maxQuantity, [Validators.required]],
              minQuantity: [element.minQuantity, [Validators.required]],
              replenishmentStatus: ['']
            })
          })
          this.elementService.getByStore(element.storename).subscribe((elements) => {
            elements.map((element) => {
              if (element.productname != this.currentProductId) {
                this.productService.getById(element.productname).subscribe((product) => {
                  this.productListInStore.push(product.name.toString());
                })
              }
            });
            this.productService.getAll().subscribe(products => {
              this.productList = products.filter((product) => !this.productListInStore.includes(product.name.toString()));
            });
          });
        });
      });
    });
    this.updateBookForm();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.reference),
        map(reference => reference ? this._filter(reference) : this.productList.slice())
      );
  }
  /* Reactive book form */
  updateBookForm() {
    this.elementForm = this.fb.group({
      elementname: [''],
      storename: [this.currentStore.id],
      productname: ['', [Validators.required]],
      location: ['', [Validators.required]],
      currentQuantity: ['', [Validators.required]],
      maxQuantity: ['', [Validators.required]],
      minQuantity: ['', [Validators.required]],
      replenishmentStatus: ['']
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.elementForm.controls[controlName].hasError(errorName);
  }

  /* Update */
  updateElementForm() {
    if (this.elementForm.valid) {
      var productName = this.elementForm.get("productname").value;
      var eltName = this.currentStore.id + productName.id;
      this.elementForm.patchValue({ elementname: eltName });
      let confirmDialog = this.confirmService.openDialog(UserMessage.confirmUpdateProduct);
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          //Update replenishment status
          if (this.elementForm.get("currentQuantity").value >= this.elementForm.get("minQuantity").value) {
            this.elementForm.patchValue({ replenishmentStatus: UserMessage.inStockStatus });
          } else {
            this.elementForm.patchValue({ replenishmentStatus: UserMessage.waitingStatus });
          }

          this.elementService.update(this.elementId, this.elementForm.value).subscribe(res => {
            this.ngZone.run(() => this.router.navigate(['/elements'], { queryParams: { storeId: this.currentStore.id }, skipLocationChange: true }));
          })
        }
        else {
          this.ngZone.run(() => this.router.navigate(['/elements'], { queryParams: { storeId: this.currentStore.id }, skipLocationChange: true }));
        }
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigate(['/elements'], { queryParams: { storeId: this.currentStore.id } }));
  }

  /* Set product name value*/
  setProductName() {
    this.elementForm.patchValue({ "productname": this.myControl.value.id });
  }

  /* Allow to display the product name*/
  displayFn(product?: Product): String | undefined {
    return product ? product.reference : undefined;
  }

  /* Autocomplete filter */
  private _filter(reference: string): Product[] {
    const filterValue = reference.toLowerCase();

    return this.productList.filter(option => option.reference.toLowerCase().indexOf(filterValue) === 0);
  }
}

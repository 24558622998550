import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';

import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';

import { User } from '../../models/user';
import { AuthenticationService, ElementService, ProductService, StoreService, UserService } from '../../services';
import { Store } from '@app/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class HomeComponent implements OnInit {

  adminRole: String = "Administrateur";
  currentUser: User;
  waitingStatus: String = "En Attente";
  orderedStatus: String = "En Cours";
  dataSourceWaitingProducts: MatTableDataSource<Element>;
  dataSourceOrderedProducts: MatTableDataSource<Element>;
  dateSourceOrderHistory: MatTableDataSource<Store>;
  listWaitingProducts: any = [];
  listOrderedProducts: any = [];
  replenishmentPlan: String;
  replenishmentDate: String;
  numberOfClients: Number;
  numberOfStores: Number;
  numberOfProducts: Number;
  numberOfElements: Number;
  cols: Number;
  cardCols: Number;
  userCardCols: Number;
  userCols: Number;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'reference', 'status'];
  displayedColumnsHistory: string[] = ['storename', 'reference', 'replenishment'];
  

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private productService: ProductService,
    private storeService: StoreService,
    private elementService: ElementService,
  ) {
  }

  private calculateCols(width: number): void {
    this.cols = width < 1200 ? 1 : 2;
    this.userCols = width < 600 ? 1: 2;
    this.cardCols = width > 400 ? width > 600 ? 3 : 2 : 1;
    this.userCardCols = width > 600 ? width > 1200 ? 3 : 2 : 1;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth),

    // get current user
    this.currentUser = this.authenticationService.currentUserValue;
   
    /**** BUILD ADMIN DASHBOARD  ****/
    if (this.currentUser.role === this.adminRole) {
      // count all users
      this.userService.getAll().subscribe(users => {
        this.numberOfClients = users.length;
        // count all stores
        this.storeService.getAll().subscribe(stores => {
          this.numberOfStores = stores.length;
          this.dateSourceOrderHistory = new MatTableDataSource<Store>(stores);
          setTimeout(() => {
            this.dateSourceOrderHistory.paginator = this.paginator;
            this.dateSourceOrderHistory.sort = this.sort;
          }, 0);
          // count all products
          this.productService.getAll().subscribe(products => {
            this.numberOfProducts = products.length;
          });
        });
      });
    }

    // /**** BUILD USER DASHBOARD  ****/
    if (this.currentUser.role !== this.adminRole) {

      // build datatable
      forkJoin(this.elementService.getByUser(this.currentUser.id), this.productService.getAll()).subscribe(
        results => {
          var elementArray = <Array<any>>results[0];
          var productArray = <Array<any>>results[1];

          this.numberOfElements = elementArray.length;

          for (let i = 0; i < elementArray.length; i++) {
            let currentElement = elementArray[i];
            currentElement.elementid = currentElement.id;

            if (currentElement.replenishmentStatus === this.waitingStatus) {
              this.listWaitingProducts.push({
                ...currentElement,
                ...(productArray.find((itmInner) => itmInner.id === currentElement.productname))
              }
              );
            }
            else if (currentElement.replenishmentStatus === this.orderedStatus) {
              this.listOrderedProducts.push({
                ...currentElement,
                ...(productArray.find((itmInner) => itmInner.id === currentElement.productname))
              }
              );
            }
          };

          this.dataSourceWaitingProducts = new MatTableDataSource<Element>(this.listWaitingProducts);
          setTimeout(() => {
            this.dataSourceWaitingProducts.paginator = this.paginator;
            this.dataSourceWaitingProducts.sort = this.sort;
          }, 0);
          this.dataSourceOrderedProducts = new MatTableDataSource<Element>(this.listOrderedProducts);
          setTimeout(() => {
            this.dataSourceOrderedProducts.paginator = this.paginator;
            this.dataSourceOrderedProducts.sort = this.sort;
          }, 0);

          // get store
          this.storeService.getByUsers(this.currentUser.id).subscribe(store => {
            let currentStore = store[0];
            this.replenishmentPlan = currentStore.replenishment;
            this.replenishmentDate = currentStore.replenishmentDate;
          }
          );
        })
    }
  }
}
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { TranslationService } from '@app/services/translate.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.css']
})
export class ExportModalComponent implements OnInit {
  columns: [];
  selectedColumns: [];
  selectedRowData: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetClientForm', { static: true }) myNgForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExportModalComponent>,
    private translationService: TranslationService
  ) {
  }

  ngOnInit() {
    this.columns = this.data.columns;
    this.selectedRowData = this.data.rowData;
  }


  validate(): void {
    const exportData = this.selectedRowData.map((item) => {
      const updatedItem: any = {};
      this.selectedColumns.map((value) => {
        updatedItem[value] = item[value];
      });
      return updatedItem;
    })
    this.data.submit(exportData);
    this.exportExcel(exportData);
    this.dialogRef.close();
  }

  exportExcel(data: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' }); // Use 'base64' instead of 'blob'

    // Create a data URI for the Excel file
    const dataUri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelData;

    const a = document.createElement('a');
    a.href = dataUri;
    a.download = 'data.xlsx';
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(dataUri);
  }

  translate(key: string): string {
    return this.translationService.translate(key, 'fr'); // Change 'fr' to switch languages
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

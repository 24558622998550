import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ProductHistory } from '@app/models/product.history';

@Injectable({ providedIn: 'root' })
export class ProductHistoryService {

    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) { }

    // Get All
    getAll() {
        return this.http.get<ProductHistory[]>(`${environment.apiUrl}/product-history`);
    }

    // record
    record(data: ProductHistory) {
        return this.http.post<ProductHistory>(`${environment.apiUrl}/product-history/record`, data);
    }
}
import { Component, ViewChild, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app/services';
import { User } from '@app/models/user';
import { ProfileComponent } from './components/_shared/profile/profile.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  currentUser: User;
  opened = true;

  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav; 

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.currentUser) {
      if (event.target.innerWidth < 768) {
        this.sidenav.fixedTopGap = 55;
        this.opened = false;
      } else {
        this.sidenav.fixedTopGap = 55
        this.opened = true;
      }
    }
  }

  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      return true;
    } else {
      return false;
    }
  }

  contactSAV() {    
    var mail = document.createElement("a");    
    mail.href = "mailto:sav@janneau.com?subject=[Armoire connectée] - Demande d'information";
    mail.click();
  }

  openUserProfile(){
    this.dialog.open(ProfileComponent, {
      width: '400px',
      data: {user : this.currentUser},
      disableClose: true
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Store } from '@app/models/store';

@Injectable({ providedIn: 'root' })
export class StoreService {

    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) { }

    // Get All
    getAll() {
        return this.http.get<Store[]>(`${environment.apiUrl}/stores`);
    }

    // Get by ID
    getById(id) {
        return this.http.get<Store>(`${environment.apiUrl}/stores/${id}`);
    }

    // Get users
    getUsers(id) {
      return this.http.get<Store>(`${environment.apiUrl}/stores/users/${id}`);
    }

    // Get by user id
    getByUsers(id) {
      return this.http.get<Store>(`${environment.apiUrl}/stores/byuser/${id}`);
    }

    // Create
    create(data: Store) {
        return this.http.post<Store>(`${environment.apiUrl}/stores/register`, data);
    }

    // Update
    update(id, data: Store) {
        return this.http.put<Store>(`${environment.apiUrl}/stores/${id}`, data);
    }

    // Delete
    delete(id) {
        return this.http.delete<Store>(`${environment.apiUrl}/stores/${id}`);
    }

}
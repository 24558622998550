import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ReadQrcodeComponent } from '../../_shared/read-qrcode/read-qrcode.component';
import { EditMystoreComponent } from '../edit-mystore/edit-mystore.component';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ElementService, ProductService, AuthenticationService, InfoService, ErrorService, ProductHistoryService } from '@app/services';
import { Element, ProductHistory, User } from 'src/app/models';
import { GenerateQrcodeComponent } from '../../_shared/generate-qrcode/generate-qrcode.component';
import { UserMessage } from '@app/components/_helpers/user.message';
import { environment } from '@environments/environment';
import { ImageViewDialogComponent } from '@app/components/_shared/image-view-dialog/image-view-dialog.component';

@Component({
  selector: 'app-mystore',
  templateUrl: './list-mystore.component.html',
  styleUrls: ['./list-mystore.component.css']
})
export class ListMystoreComponent implements OnInit {

  elementList: any = [];
  dataSource: MatTableDataSource<Element>;
  elementForm: FormGroup;
  currentUser: User;
  productHistoryData: any;
  quantityChange: string;
  apiUrl = environment.apiUrl;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  showImage = false;
  displayedColumns: string[] = ['designation', 'reference', 'location', 'currentQuantity', 'minQuantity', 'qrcode', 'action'];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private elementService: ElementService,
    private productService: ProductService,
    private authenticationService: AuthenticationService,
    private infoService: InfoService,
    private errorService: ErrorService,
    private productHistoryService: ProductHistoryService
  ) {
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.refreshData();
  }

  /* Filter */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Update Quantity */
  openDialog(element): void {
    const dialogEdit = this.dialog.open(EditMystoreComponent, {
      width: '300px',
      data: { productname: element.name, reference: element.reference, currentQuantity: parseInt(element.currentQuantity) }
    });

    dialogEdit.afterClosed().subscribe(newQuantity => {
      if (newQuantity != undefined && newQuantity != null) {
        // update replenishment status
        let newReplenishmentStatus;
        if (newQuantity < element.minQuantity) {
          newReplenishmentStatus = UserMessage.waitingStatus;
        } else {
          newReplenishmentStatus = UserMessage.inStockStatus;
        }

        this.elementForm = this.fb.group({
          elementname: element.elementname,
          storename: element.storename,
          productname: element.id,
          currentQuantity: newQuantity,
          replenishmentStatus: newReplenishmentStatus
        });

        if (this.elementForm.value.currentQuantity > element.currentQuantity) {
          this.quantityChange = ` + ${this.elementForm.value.currentQuantity - element.currentQuantity}`;
        } else {
          if (this.elementForm.value.currentQuantity < element.currentQuantity) {
            this.quantityChange = ` - ${Math.abs(this.elementForm.value.currentQuantity - element.currentQuantity)}`;
          }
        }

        if (this.quantityChange) {
          this.productHistoryData = {
            storename: this.elementForm.value.storename,
            client: this.currentUser.id,
            productname: this.elementForm.value.productname,
            quantityChange: this.quantityChange
          };
          this.productHistoryService.record(this.productHistoryData).subscribe();
          this.quantityChange = undefined;
        }

        this.elementService.update(element.elementid, this.elementForm.value).subscribe(
          (res) => {
            this.infoService.openFadeDialog(UserMessage.storeUpdateOK, 2000);
            this.refreshData();
          });
      }
    });
  }

  /* Scan QR Code */
  scanQRCode() {
    const dialogScan = this.dialog.open(ReadQrcodeComponent, {
      width: '350px',
      disableClose: true
    });

    dialogScan.afterClosed().subscribe(result => {
      const scanResult = dialogScan.componentInstance.scanResult;
      switch (scanResult) {
        case 'close': {
          break;
        }
        case 'error': {
          this.errorService.openDialog(new Error(UserMessage.qrCodeError));
          break;
        }
        case null: {
          this.errorService.openDialog(new Error(UserMessage.qrCodeError));
          break;
        }
        default: {
          let isAuthorized = false;
          let currentElement = null;
          this.elementService.getByUser(this.currentUser.id).subscribe(elements => {
            for (const element of elements) {
              if (element.id === scanResult) {
                currentElement = element;
                isAuthorized = true;
                break;
              }
            }

            if (isAuthorized) {
              this.productService.getById(currentElement.productname).subscribe(product => {
                const dialogEdit = this.dialog.open(EditMystoreComponent, {
                  width: '300px',
                  data: { productname: product.name, reference: product.reference, currentQuantity: parseInt(currentElement.currentQuantity) }
                });

                dialogEdit.afterClosed().subscribe(newQuantity => {

                  if (newQuantity != undefined && newQuantity != null) {

                    // update replenishment status
                    let newReplenishmentStatus;
                    if (newQuantity < currentElement.minQuantity) {
                      newReplenishmentStatus = UserMessage.waitingStatus;
                    } else {
                      newReplenishmentStatus = UserMessage.inStockStatus;
                    }

                    this.elementForm = this.fb.group({
                      elementname: currentElement.elementname,
                      storename: currentElement.storename,
                      productname: currentElement.productname,
                      currentQuantity: newQuantity,
                      replenishmentStatus: newReplenishmentStatus
                    });

                    this.elementService.update(scanResult, this.elementForm.value).subscribe(
                      (res) => {
                        this.infoService.openFadeDialog(UserMessage.storeUpdateOK, 2000);
                        this.refreshData();
                      });
                  }
                });
              });
            } else {
              this.errorService.openFadeDialog(new Error(UserMessage.unavailableProduct), 3000);
            }
          });
        }
      }
    });
  }

  /* Generate QR Code */
  generateQRCode(index: number, product) {
    product.productname = product.name;
    this.dialog.open(GenerateQrcodeComponent, {
      width: '350px',
      disableClose: true,
      data: { product }
    });
  }

  /* Refresh data table */
  refreshData() {
    forkJoin(this.elementService.getByUser(this.currentUser.id), this.productService.getAll()).subscribe(
      (res) => {
        const elementArray = res[0] as Array<any>;
        const productArray = res[1] as Array<any>;

        // Empty current table
        this.elementList = [];

        for (let i = 0; i < elementArray.length; i++) {
          elementArray[i].elementid = elementArray[i].id;
          this.elementList.push({
            ...elementArray[i],
            ...(productArray.find((itmInner) => itmInner.id === elementArray[i].productname))
          }
          );
        }
        this.dataSource = new MatTableDataSource<Element>(this.elementList);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);
      });
  }

  toggleImageShow(event: MatSlideToggleChange) {
    this.showImage = event.checked;
    if (this.showImage) {
      this.displayedColumns = ['image', 'designation', 'reference', 'location', 'currentQuantity', 'minQuantity', 'qrcode', 'action'];
    } else {
      this.displayedColumns = ['designation', 'reference', 'location', 'currentQuantity', 'minQuantity', 'qrcode', 'action'];
    }
  }

  openImageViewDialog(url: string) {
    this.dialog.open(ImageViewDialogComponent, {
      panelClass: 'image-view-dialog',
      data: { imageUrl: url }
    });
  }
}

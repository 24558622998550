import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { GenerateQrcodeComponent } from '../../_shared/generate-qrcode/generate-qrcode.component';
import { ActivatedRoute } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';

import { ElementService, ProductService, StoreService, ConfirmService, InfoService, ExportService } from '@app/services';
import { Element } from '@app/models/element';
import { UserMessage } from '@app/components/_helpers/user.message';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExportModalComponent } from '@app/components/_shared/export-modal/export-modal.component';

@Component({
  selector: 'app-list-store-product',
  templateUrl: './list-store-product.component.html',
  styleUrls: ['./list-store-product.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ListStoreProductComponent implements OnInit {

  elementList: any = [];
  dataSource: MatTableDataSource<Element>;
  storeId: string;
  storeName: string;
  fileToUpload: File = null;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'productname', 'reference', 'location', 'currentQuantity', 'maxQuantity', 'minQuantity', 'status', 'qrcode', 'action'
  ];
  displaySelectColumns: string[] = [
    'productname', 'reference', 'location', 'currentQuantity', 'maxQuantity', 'minQuantity', 'replenishmentStatus'
  ];

  constructor(
    private actRoute: ActivatedRoute,
    private http: HttpClient,
    private infoService: InfoService,
    private dialog: MatDialog,
    private elementService: ElementService,
    private productService: ProductService,
    private storeService: StoreService,
    private confirmService: ConfirmService,
    private exportService: ExportService,
  ) {
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(params => {
      this.storeId = params.storeId;
      this.refreshData();
    });
  }

  /* Filter */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Table actions*/
  deleteElement(index: number, element) {
    const confirmDialog = this.confirmService.openDialog(UserMessage.confirmDeleteProduct);
    confirmDialog.afterClosed().subscribe((res) => {
      if (res) {
        const data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
        this.dataSource.data = data;
        this.elementService.delete(element.id).subscribe();
      }
    });
  }

  /* File upload */
  fileUpload(files: FileList) {
    this.fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('productsList', this.fileToUpload, this.storeId);
    this.http.post(`${environment.apiUrl}/files/upload-store-products`, formData)
      .subscribe((res: any) => {
        this.infoService.openFadeDialog(res.message, 2000);
        setTimeout(() => {
          this.refreshData();
        }, 3000);
      });
  }

  /* Generate QR Code */
  generateQRCode(index: number, product) {
    this.dialog.open(GenerateQrcodeComponent, {
      width: '350px',
      disableClose: true,
      data: { product }
    });
  }

  /* Refresh data table */
  refreshData() {
    this.elementService.getByStore(this.storeId).subscribe(elements => {
      this.elementList = elements;
      // Replace product id with product name
      for (const element of this.elementList) {
        element.elementid = element.id;
        this.productService.getById(element.productname).subscribe(product => {
          element.productname = product.name;
          element.reference = product.reference;
        });
      }
      // Get store name
      this.storeService.getById(this.storeId).subscribe(store => {
        this.storeName = store.storename;
      });
      this.dataSource = new MatTableDataSource<Element>(this.elementList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    });
  }

  // * export data as Excel File */
  onExportData(data: any) {
    // this.exportService.storeExport(data).subscribe();
  }

  openDialog() {
    this.dialog.open(ExportModalComponent, {
      width: '400px',
      data: { columns : this.displaySelectColumns, rowData: this.dataSource.filteredData, submit: (data: any) => this.onExportData(data) },
    });
  }
}



import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent, ImageTransform, LoadedImage} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.css']
})
export class ImageCropDialogComponent implements OnInit {
  @Input() imageChangedEvent?: any;
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<string>();

  showCropper = false;
  transform: ImageTransform = {};
  croppedImage: string | null | undefined;

  constructor(
    public dialogRef: MatDialogRef<ImageCropDialogComponent>,
  ) {
  }

  ngOnInit(): void {

  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage): void {
    this.showCropper = true;
    const imageContainer = document.querySelector('.crop-dialog .modal-body');
    const imageElement = document.querySelector('.crop-dialog .modal-body image-cropper img');

    if (imageContainer && imageElement) {
      const imageWidth = image.original.size.width;
      const imageHeight = image.original.size.height;

      const ratio = imageContainer.clientWidth / imageContainer.clientHeight;

      if (imageHeight < 390) {
        if (imageWidth <= imageHeight * ratio) {
          imageElement.setAttribute('style', 'min-height: 390px;');
        } else {
          imageElement.setAttribute('style', 'min-width: 100%;');
        }
      }
    }
  }

  handleCancelCrop(): void {
    this.croppedImage = undefined;
    this.transform = {};
    this.cancel.emit();
    this.dialogRef.close();
  }

  handleSaveCrop(): void {
    if (this.croppedImage && this.croppedImage.length > 0) {
      this.save.emit(this.croppedImage);
    }

    this.dialogRef.close();
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCropDialogComponent} from '@app/components/_shared/image-crop-dialog/image-crop-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-image-dropzone',
  templateUrl: './image-dropzone.component.html',
  styleUrls: ['./image-dropzone.component.css']
})
export class ImageDropzoneComponent implements OnInit {
  @ViewChild('file', { static: true }) fileRef: ElementRef | undefined;
  @Output() changeImage = new EventEmitter<File | undefined>();
  @Input() imagePath?: string;

  safeBackgroundImage: SafeStyle;
  image: string;
  apiUrl = environment.apiUrl;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  public async handleFileInput(event: any): Promise<void> {
    if (event.target.files == null || event.target.files.length !== 1) {
      return;
    }
    const file = event.target.files[0];

    const dialogRef = this.dialog.open(ImageCropDialogComponent, {
      width: '500px',
      panelClass: 'crop-dialog',
    });

    dialogRef.componentInstance.imageChangedEvent = event;
    dialogRef.componentInstance.cancel.subscribe(() => {
      this.resetImage();
    });

    dialogRef.componentInstance.save.subscribe(async (croppedImage: string) => {
      this.image = croppedImage;
      this.safeBackgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url('${croppedImage}')`);
      const croppedFile = await this.base64ToFile(croppedImage, file.name);
      this.changeImage.emit(croppedFile);
    });
  }

  public async base64ToFile(base64Image: string, name: string) {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type });
    return new File([blob], name);
  }

  resetImage(): void {
    if (this.fileRef) {
      this.fileRef.nativeElement.value = null;
    }
  }

  removeImage(event: MouseEvent) {
    event.stopPropagation();
    this.safeBackgroundImage = undefined;
    this.fileRef.nativeElement.value = null;
    this.image = undefined;
    this.imagePath = undefined;
    this.changeImage.emit(undefined);
  }
}

import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { UserService, ConfirmService, ErrorService } from 'src/app/services';
import { UserMessage } from '@app/components/_helpers/user.message';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cols: Number;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetClientForm', { static: true }) myNgForm;

  userForm: FormGroup;
  userId: string;
  selected = 'user';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private actRoute: ActivatedRoute,
    private userService: UserService,
    private confirmService: ConfirmService,
    private errorService: ErrorService
  ) {
  }

  private calculateCols(width: number): void {
    this.cols = width < 1024 ? 1 : 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth);
    this.actRoute.queryParams.subscribe(params => {
      this.userId = params.userId;
      this.userService.getById(this.userId).subscribe(user => {
        this.userForm = this.fb.group({
          username: [user.username, [Validators.required]],
          password: [user.password, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
          company: [user.company, [Validators.required]],
          email: [user.email, [Validators.required, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]],
          role: [user.role, [Validators.required]]
        })
      })
    })
    this.updateBookForm();
  }

  /* Reactive book form */
  updateBookForm() {
    this.userForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]],
      role: ['', [Validators.required]]
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }

  /* Update */
  updateUserForm() {
    if (this.userForm.valid) {
      let confirmDialog = this.confirmService.openDialog(UserMessage.confirmUpdateClient);
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.userService.update(this.userId, this.userForm.value).subscribe(res => {
            this.ngZone.run(() => this.router.navigateByUrl('/clients'))
          });
        }
        else {
          this.ngZone.run(() => this.router.navigateByUrl('/clients'))
        }
      })
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/clients'));
  }
}

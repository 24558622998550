import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/models/user';

@Injectable({ providedIn: 'root' })
export class UserService {

    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) { }

    // Get All
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    // Get by ID
    getById(id) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    // Create
    create(data: User) {
        return this.http.post<User>(`${environment.apiUrl}/users/register`, data);
    }

    // Update
    update(id, data: User) {
        return this.http.put<User>(`${environment.apiUrl}/users/${id}`, data);
    }

    // Delete
    delete(id) {
        return this.http.delete<User>(`${environment.apiUrl}/users/${id}`);
    }

}
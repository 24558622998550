import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, ErrorService, ConfirmService } from '@app/services';
import { User } from '@app/models';
import { UserMessage } from '@app/components/_helpers/user.message';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  currentUser: User;
  userForm: FormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetClientForm', { static: true }) myNgForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private confirmService: ConfirmService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.currentUser = this.data.user;
    this.userService.getById(this.currentUser.id).subscribe(user => {
      this.userForm = this.fb.group({
        username: [user.username, [Validators.required]],
        password: [user.password, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
        company: [user.company, [Validators.required]],
        email: [user.email, [Validators.required, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]],
        role: [user.role, [Validators.required]]
      })
    })
    this.updateBookForm();
  }

  /* Reactive book form */
  updateBookForm() {
    this.userForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]],
      role: ['', [Validators.required]]
    })
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }

  /* Update */
  updateUserForm() {
    if (this.userForm.valid) {
      let confirmDialog = this.confirmService.openDialog(UserMessage.confirmUpdateProfil);
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.userService.update(this.currentUser.id, this.userForm.value).subscribe(res => {
            this.dialogRef.close();
          })
        }
        else {
          this.dialogRef.close();
        }
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}

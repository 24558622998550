import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { UserService, ConfirmService, ErrorService } from 'src/app/services';
import { User } from 'src/app/models/user';
import { UserMessage } from '@app/components/_helpers/user.message';
import { ExportService } from '@app/services/export.service';
import { ExportModalComponent } from '@app/components/_shared/export-modal/export-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListClientComponent implements OnInit {

  usersList: any = [];
  dataSource: MatTableDataSource<User>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['username', 'company', 'email', 'role', 'action'];
  displaySelectColumns: string[] = ['username', 'company', 'email', 'role'];

  constructor(
    private userService: UserService,
    private confirmService: ConfirmService,
    private exportService: ExportService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'custom-svg-icon', // Icon name to use in your template
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/export-icon.svg')
    );
  }

  ngOnInit() {
    this.userService.getAll().subscribe(users => {
      this.usersList = users;
      this.dataSource = new MatTableDataSource<User>(this.usersList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    })
  }

  /* Filter */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Table actions*/
  deleteUser(index: number, user) {
    let confirmDialog = this.confirmService.openDialog(UserMessage.confirmDeleteClient);
    confirmDialog.afterClosed().subscribe((res) => {
      if (res) {
        const data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
        this.dataSource.data = data;
        this.userService.delete(user.id).subscribe();
      }
    });
  }

  //* export data as Excel File */
  onExportData(data: any) {
    this.exportService.clientExport(data).subscribe();
  }

  openDialog(){
    this.dialog.open(ExportModalComponent, {
      width: '400px',
      data: { columns : this.displaySelectColumns, rowData: this.dataSource.filteredData, submit: (data: any) => this.onExportData(data) },
    });
  }
}

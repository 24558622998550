import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AbstractControl, FormBuilder} from '@angular/forms';

import {UserService} from '@app/services/user.service';
import {StoreService} from '@app/services/store.service';
import {ProductHistoryService, ProductService} from '@app/services';
import {ExportService} from '@app/services/export.service';
import {MatDialog} from '@angular/material';
import {ExportModalComponent} from '@app/components/_shared/export-modal/export-modal.component';
import {HistoryFilter, ProductHistory} from '@app/models';
import {MatSelectChange} from '@angular/material/select';
import {TranslationService} from '@app/services/translate.service';

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductHistoryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  readonly formControl: AbstractControl;

  historyList: any = [];
  dataSourceFilters: MatTableDataSource<ProductHistory>;

  startDate: Date;
  endDate: Date;
  filterValues: any;
  filters: string;

  products: string[];
  stores: string[];
  clients: string[];
  historyFilters: HistoryFilter[] = [];
  defaultValue = 'All';
  filterDictionary = new Map<string, string>();

  displayedColumns: string[] = ['storename', 'client', 'productname', 'quantityChange', 'createdDate'];
  displaySelectColumns: string[] = ['storename', 'client', 'productname', 'quantityChange', 'createdDate'];

  constructor(
    private storeService: StoreService,
    private userService: UserService,
    private productService: ProductService,
    private productHistoryService: ProductHistoryService,
    private exportService: ExportService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private translationService: TranslationService
  ) {
    this.matIconRegistry.addSvgIcon(
      'custom-svg-icon', // Icon name to use in your template
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/export-icon.svg')
    );

    this.formControl = this.formBuilder.group({
      storename: '',
      client: '',
      productname: '',
    });
   }

  ngOnInit() {
    this.productHistoryService.getAll().subscribe(async history => {
      this.historyList = history.map((item) => {
        return {
          ...item,
          storename: item.storename ? item.storename.storename : '',
          client: item.client ? item.client.username : '',
          productname: item.productname ? item.productname.name : '',
        };
      });

      this.dataSourceFilters = new MatTableDataSource<ProductHistory>(this.historyList);
      setTimeout(() => {
        this.dataSourceFilters.paginator = this.paginator;
        this.dataSourceFilters.sort = this.sort;
      }, 0);

      this.stores = this.getCategory(this.historyList, 'storename');
      this.historyFilters.push({ name: 'storename', options: this.stores, defaultValue: this.defaultValue });
      this.clients = this.getCategory(this.historyList, 'client');
      this.historyFilters.push({ name: 'client', options: this.clients, defaultValue: this.defaultValue });
      this.products = this.getCategory(this.historyList, 'productname');
      this.historyFilters.push({ name: 'productname', options: this.products, defaultValue: this.defaultValue });
      this.columnFilterUpdate();
    });
  }

  onDateRangeChange() {
    if (this.startDate && this.endDate) {
      this.dataSourceFilters = new MatTableDataSource<ProductHistory>(this.historyList);
      const data = this.dataSourceFilters.filteredData;
      const filteredByDate = data.filter(item => {
        const itemDate = new Date(item.createdDate);
        return itemDate >= this.startDate && itemDate <= this.endDate;
      });
      this.dataSourceFilters = new MatTableDataSource<ProductHistory>(filteredByDate);
      this.columnFilterUpdate();
      setTimeout(() => {
        this.dataSourceFilters.paginator = this.paginator;
        this.dataSourceFilters.sort = this.sort;
      }, 0);
    }
  }

  columnFilterUpdate() {
    this.dataSourceFilters.filterPredicate =  ((record, filter) => {

      this.filterValues = new Map(JSON.parse(filter));

      const keys = Array.from(this.filterValues.keys());
      let isMatch = false;

      for (const key of keys) {
        isMatch = (record[key as keyof ProductHistory] === this.filterValues.get(key)) || (this.filterValues.get(key) === 'All');
        if (!isMatch) {
          return false;
        }
      }
      return isMatch;
    });

    this.dataSourceFilters.filter = this.filters;
  }

  applyHistFilter(ob: MatSelectChange, historyFilter: HistoryFilter) {
    this.filterDictionary.set(historyFilter.name, ob.value);
    const jsonString = JSON.stringify(Array.from(this.filterDictionary.entries()));

    this.filters = jsonString;

    this.dataSourceFilters.filter = this.filters;
  }

  // * export data as Excel File */
  onExportData(data: any) {
    this.exportService.productHistoryExport(data).subscribe();
  }

  openDialog() {
    this.dialog.open(ExportModalComponent, {
      width: '400px',
      data: {
        columns : this.displaySelectColumns,
        rowData: this.dataSourceFilters.filteredData,
        submit: (data: any) => this.onExportData(data)
      },
    });
  }

  translate(key: string): string {
    return this.translationService.translate(key, 'fr'); // Change 'fr' to switch languages
  }

  getCategory(data: any, type: string): string[] {
    const set = {};
    const typeSet = [];
    data.map(item => {
      const product = item[type];
      if (!set[product]) {
        set[product] = true;
        typeSet.push(product);
      }
    });
    typeSet.unshift('All');
    return typeSet;
  }
}

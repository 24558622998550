import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ProductService } from '@app/services/product.service';
import { ErrorService } from '@app/services';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cols: number;
  productImage: File;

  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('resetProductForm', { static: true }) myNgForm;

  productForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private productService: ProductService,
    private errorService: ErrorService
  ) {
  }

  private calculateCols(width: number): void {
    this.cols = width < 1024 ? 1 : 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCols(event.target.innerWidth);
  }

  ngOnInit() {
    this.calculateCols(window.innerWidth);
    this.submitBookForm();
  }

  /* Reactive book form */
  submitBookForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]],
      family: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      material: ['', [Validators.required]],
      cost: ['', [Validators.required]],
      supplier: ['', [Validators.required]],
      consumer: ['', [Validators.required]],
    });
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.productForm.controls[controlName].hasError(errorName);
  }

  /* Submit */
  submitProductForm() {
    if (this.productForm.valid) {
      const formData = new FormData();
      formData.append('name', this.productForm.value.name);
      formData.append('family', this.productForm.value.family);
      formData.append('reference', this.productForm.value.reference);
      formData.append('material', this.productForm.value.material);
      formData.append('cost', this.productForm.value.cost);
      formData.append('supplier', this.productForm.value.supplier);
      formData.append('consumer', this.productForm.value.consumer);

      if (this.productImage) {
        formData.append('image', this.productImage);
      }

      this.productService.create(formData).subscribe(
        (res) => {
          this.ngZone.run(() => this.router.navigateByUrl('/products'));
        });
    }
  }

  /* Cancel */
  cancel() {
    this.ngZone.run(() => this.router.navigateByUrl('/products'));
  }

  changeImage(image: File) {
    this.productImage = image;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '@app/components/_shared/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmService {

    constructor(public dialog: MatDialog) { }

    openDialog(data) {
        let dialog = this.dialog.open(ConfirmDialogComponent, {
            width: '320px',
            disableClose: true,
            data: data
        });
        return dialog;
    }
}
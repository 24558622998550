import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ExportService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  // product
  productExport(data: any[]) {
      return this.http.post<any>(`${environment.apiUrl}/export/product`, data);
  }
  //store
  storeExport(data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}/export/store`, data);
  }
  clientExport(data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}/export/client`, data);
  }
  productHistoryExport(data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}/export/product-history`, data);
  }
}
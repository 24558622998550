import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { UserService } from '@app/services/user.service';
import { StoreService } from '@app/services/store.service';
import { Store } from '@app/models/store';
import { ConfirmService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';
import { ExportService } from '@app/services/export.service';
import { MatDialog } from '@angular/material';
import { ExportModalComponent } from '@app/components/_shared/export-modal/export-modal.component';

@Component({
  selector: 'app-list-store',
  templateUrl: './list-store.component.html',
  styleUrls: ['./list-store.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ListStoreComponent implements OnInit {

  storeList: any = [];
  dataSource: MatTableDataSource<Store>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['storename', 'reference', 'client', 'replenishment', 'element', 'action'];
  displaySelectColumns: string[] = ['storename', 'reference', 'client', 'replenishment'];

  constructor(
    private storeService: StoreService,
    private userService: UserService,
    private confirmService: ConfirmService,
    private exportService: ExportService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'custom-svg-icon', // Icon name to use in your template
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/export-icon.svg')
    );
  }

  ngOnInit() {
    this.storeService.getAll().subscribe(stores => {
      this.storeList = stores;
      // Replace user id with user name
      for (let store of this.storeList) {
        this.userService.getById(store.client).subscribe(client => {
          store.client = client.username;
        })
      }
      this.dataSource = new MatTableDataSource<Store>(this.storeList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    })
  }

  /* Filter */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Table actions*/
  deleteStore(index: number, store) {
    let confirmDialog = this.confirmService.openDialog(UserMessage.confirmDeleteStore);
    confirmDialog.afterClosed().subscribe((res) => {
      if (res) {
        const data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
        this.dataSource.data = data;
        this.storeService.delete(store.id).subscribe();
      }
    })
  }

  //* export data as Excel File */
  onExportData(data: any) {
    this.exportService.storeExport(data).subscribe();
  }

  openDialog(){
    this.dialog.open(ExportModalComponent, {
      width: '400px',
      data: { columns : this.displaySelectColumns, rowData: this.dataSource.filteredData, submit: (data: any) => this.onExportData(data) },
    });
  }
}

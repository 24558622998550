import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ProductService } from '@app/services/product.service';
import { Product } from '@app/models/product';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { InfoService, ConfirmService } from '@app/services';
import { UserMessage } from '@app/components/_helpers/user.message';
import { ExportService } from '@app/services/export.service';
import { ExportModalComponent } from '@app/components/_shared/export-modal/export-modal.component';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListProductComponent implements OnInit {

  productList: any = [];
  dataSource: MatTableDataSource<Product>;
  fileToUpload: File = null;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['name', 'family', 'reference', 'material', 'cost', 'supplier', 'consumer', 'actions'];
  displaySelectColumns: string[] = ['name', 'family', 'reference', 'material', 'cost', 'supplier', 'consumer'];
  constructor(
    private http: HttpClient,
    private productService: ProductService,
    private infoService: InfoService,
    private confirmService: ConfirmService,
    private exportService: ExportService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer
  ) { 
      this.matIconRegistry.addSvgIcon(
        'custom-svg-icon', // Icon name to use in your template
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/export-icon.svg')
      );
  }

  ngOnInit() {
    this.refreshData();
  }

  /* Filter */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Table actions*/
  deleteProduct(index: number, product) {
    let confirmDialog = this.confirmService.openDialog(UserMessage.confirmDeleteProduct);
    confirmDialog.afterClosed().subscribe((res) => {
      if (res) {
        let data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
        this.dataSource.data = data;
        this.productService.delete(product.id).subscribe();
      }
    });
  }

  /* File upload */
  fileUpload(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append('productsList', this.fileToUpload, this.fileToUpload.name);
    this.http.post(`${environment.apiUrl}/files/upload-products-repository`, formData)
      .subscribe((res) => {
        this.infoService.openFadeDialog(res['message'], 2000);
        this.refreshData();
      })
  }

  /* Refresh data table */
  refreshData() {
    this.productService.getAll().subscribe(products => {
      this.productList = products;
      this.dataSource = new MatTableDataSource<Product>(this.productList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    })
  }

  //* export data as Excel File */
  onExportData(data: any) {
    this.exportService.productExport(data).subscribe();
  }

  openDialog(){
    this.dialog.open(ExportModalComponent, {
      width: '400px',
      data: { columns : this.displaySelectColumns, rowData: this.dataSource.filteredData, submit: (data: any) => this.onExportData(data) },
    });
  }
}
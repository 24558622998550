import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface EditMyStoreProductDialog {
  product_name: string;
  quantity: number;
}

@Component({
  selector: 'app-edit-mystore',
  templateUrl: './edit-mystore.component.html',
  styleUrls: ['./edit-mystore.component.css']
})
export class EditMystoreComponent implements OnInit {

  userNumber:number=1;

  constructor(   
    public dialogRef: MatDialogRef<EditMystoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditMyStoreProductDialog) {}

  ngOnInit() {    
  }

  onNoClick(): void {   
    this.dialogRef.close();
  }

}
